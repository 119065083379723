.root {
    display: flex;
    flex-direction: column;
}

.titleSizeRoot {
    font-family: 'TWK Everett', sans-serif;
    font-weight: var(--font-weight-5);
    color: var(--text-invert);
    display: flex;
}

.icon {
    margin-left: var(--spacing-size-two);
    margin-top: var(--spacing-size-two);
}

.subTitleSizeRoot {
    font-family: 'TWK Everett', sans-serif;
    font-weight: var(--font-weight-4);
    color: var(--text-invert);
    padding-top: var(--spacing-size-four);
}

.titleSizeLarge {
    font-size: var(--font-size-title2);
    line-height: var(--line-height-title2);
}

.titleSizeMedium {
    font-size: var(--font-size-title3);
    line-height: var(--line-height-title3);
}

.titleSizeSmall {
    font-size: var(--font-size-title4);
    line-height: var(--line-height-title4);
}

.subTitleSizeLarge {
    font-size: var(--font-size-copy1);
    line-height: var(--line-height-copy1);
}

.subTitleSizeMedium {
    font-size: var(--font-size-copy2);
    line-height: var(--line-height-copy2);
}

.subTitleSizeSmall {
    font-size: var(--font-size-copy3);
    line-height: var(--line-height-copy3);
}

.setWidth {
    width: 50%;
}

@media screen and (max-width: 991px) {
    .titleSizeLarge {
        font-size: var(--font-size-title4);
        line-height: var(--line-height-title4);
    }

    .titleSizeMedium {
        font-size: var(--font-size-title3);
        line-height: var(--line-height-title3);
    }

    .titleSizeSmall {
        font-size: var(--font-size-title5);
        line-height: var(--line-height-title5);
    }

    .subTitleSizeLarge {
        font-size: var(--font-size-copy2);
        line-height: var(--line-height-copy2);
    }

    .subTitleSizeMedium {
        font-size: var(--font-size-copy1);
        line-height: var(--line-height-copy1);
    }

    .subTitleSizeSmall {
        font-size: var(--font-size-copy3);
        line-height: var(--line-height-copy3);
    }
}

@media screen and (max-width: 767px) {
    .setWidth {
        width: 100%;
    }
}
